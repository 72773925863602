.sideBarDrawerPlaceHolder {
    width: 2rem;
    height: 100%;
}

.sideBarDrawer {
    z-index: 100;

    --sidebar-visible: 0;

    position: absolute;
    right: calc((1 - var(--sidebar-visible)) * -20rem + var(--sidebar-visible) * 0rem);

    width: 22rem;
    height: 100%;
    max-height: 100%;

    background-color: white;

    display: flex;
    flex-direction: row;

    box-shadow: calc(var(--sidebar-visible) * 2px) 0 calc(var(--sidebar-visible) * 8px) #373737;

    transition: right 0.2s ease-in-out;
}

.actionTray {
    width: 2rem;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #E0E0E0;

    display: flex;
    flex-direction: column;
}

.actionTrayButton {
    --custom-background-color: transparent;

    width: 1.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: .2rem;
    padding: .2rem;

    background-color: var(--custom-background-color);

    transition: all 0.2s ease-in-out;
}

.actionTrayButton:hover {
    background-color: #E0E0E0;
}

.actionTrayButtonExpand {
    height: 1.6rem;
    transform: rotate(calc(var(--sidebar-visible) * 180deg));
}

.actionTrayButtonScript {
    pointer-events: none;
    writing-mode: vertical-lr;
    text-orientation: mixed;

    user-select: none;
}

.infoTray {
    flex-grow: 1;
    max-height: 100%;
}
