.itemsContainer {
    pointer-events: none;
    outline: none;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

/*
    all children of items container need to recieve pointer events but the itemsContainer itself should not
 */
.itemsContainer * {
    pointer-events: auto;
}

.blockContainer {
    pointer-events: none;
    overflow: visible;

    width: 16rem;
    height: 16rem;
    position: relative;
}

.blockContainer * {
    pointer-events: auto;
}

.blockWrapper {
    position: absolute;
    box-shadow: #373737 1px 1px 8px;
}

.blockWrapper:hover {
    z-index: 30;
    cursor: grab;
}

.editDialogContainer {
    overflow: hidden;

    width: 48rem;
    height: 26rem;

    z-index: 20;

    background-color: white;
    border-radius: .5rem;
    box-shadow: #373737 1px 1px 8px;
}

.editDialogToolBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #E0E0E0;
}

.editDialogToolBarSpacer {
    flex-grow: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
}

.actionButton {
    width: 3rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.closeButton {
    background-color: #E53935;
}
