.pageSkeleton {
    background-color: white;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@keyframes skeleton-loading {
    from { background-color: lightgrey }
    to { background-color: white }
}

.textLineSkeleton {
    border-radius: 4px;
    animation: skeleton-loading 1s ease-in-out infinite alternate;
}
