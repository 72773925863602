.readingOrderItem {
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-height: 30px;
    line-height: var(--lh);
    padding: 2px 10px 2px 10px; /* top right bottom left */
    margin: 1px 10px 2px 2px; /* top right bottom left */
    border-width: 2px;
    border-style: solid;
    border-radius: .5em; /* creates rounded corners */
    transition: background-color 0.2s ease-in-out;
    width: 16rem; /* bit of a hack to make sure the items don't look weird*/

    overflow: hidden;
    text-overflow: ellipsis;
}
