.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: .25rem;
    gap: .25rem;

    overflow: visible;
    position: relative;

    transition: background-color .2s ease-in-out;
}

.selector {
    position: absolute;
    top: 100%;
    left: 0;

    z-index: 10;

    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    border-radius: 0 .5rem .5rem .5rem;
    overflow: hidden;

    box-shadow: #373737 1px 1px 4px;
}

.container:hover .selector {
    display: flex;
}

.selectorItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    padding: .25rem;
    gap: .25rem;

    cursor: pointer;
    transition: scale .2s ease-in-out;
}

.selectorItem:hover {
    scale: 1.05;
}

.selectorItem * {
    pointer-events: none;
}
