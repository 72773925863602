@keyframes border-skeleton-loading {
    from { opacity: .5 }
    to { opacity: 1 }
}

.contentblockBorderSkeleton {
    position: absolute;
    border-radius: 2px;

    animation: border-skeleton-loading 1s infinite ease-in-out alternate;
}

.headingBorderSkeleton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textBorderSkeleton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.mathExpressionBorderSkeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mathExpressionInnerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.mathExpressionFractionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tableBorderSkeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tableRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.imageBorderSkeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textLineSkeleton {
    border-radius: 2px;
    animation: border-skeleton-loading 1s infinite ease-in-out alternate;
}
