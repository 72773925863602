.anchor {
    cursor: pointer;
    position: absolute;
    width: 2rem;
    height: 2rem;

    scale: 0.75;

    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
