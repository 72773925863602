.hotZone {
    background-color: white;
    z-index: 50; /* to put it always in front */
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: .5;
    transition: all ease-in-out 0.333s;
    border-radius: 4px;

    box-shadow: #373737 2px 2px 4px;

}

.hotZone:hover {
    opacity: 1;
}
