.inlineIcon {
    margin: -.4rem;
}

.inputKey {
    display: inline-block;
    border-color: lightgray;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    padding: .1rem;
}
