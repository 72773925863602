.issueItem {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    vertical-align: center;
    height: 50px;
    line-height: 25px;
    padding: 2px 10px 2px 10px; /* top right bottom left */
    margin: 2px 10px 2px 2px; /* top right bottom left */
    border-width: 2px;
    border-style: solid;
    border-radius: .5em;
    width: 85%;
}
